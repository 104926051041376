import React, { useState } from 'react';
import {
    Box,
    Stack,
    Alert,
    AlertIcon,
    Button,
    Text,
    VStack,
} from "@chakra-ui/react";
import * as XLSX from 'xlsx';
import Card from "components/card/Card.js";
import EmailAddress from './components/EmailAddress';
import { useListContext } from '../../../contexts/listContext';



const Index = () => {
    const { list, checkedPlatforms, abuse_type, dateFilter, platforms } = useListContext();
    const [selectedPlatform, setSelectedPlatform] = useState(''); // Added state for platform
    const [successAlert, setSuccessAlert] = useState('');
    const [failureAlert, setFailureAlert] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [receivingEmail, setReceivingEmail] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [productDetails, setProductDetails] = useState([]); // For storing extracted data
    const [mailDraft, setMailDraft] = useState("");

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setAttachments((prevFiles) => [...prevFiles, ...files]);


        files.forEach((file) => {
            if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel") {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const binaryStr = e.target.result;
                    const workbook = XLSX.read(binaryStr, { type: "binary" });

                    // Read the first sheet
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    // Convert sheet data to JSON
                    const data = XLSX.utils.sheet_to_json(sheet);

                    // Append data to productDetails state
                    setProductDetails((prevDetails) => [...prevDetails, ...data]);
                };

                reader.onerror = () => {
                    handleFailureAlert("Error reading the Excel file.");
                };

                reader.readAsBinaryString(file);
            }

        });
    };


    const handleSuccessAlert = (alertMessage) => {
        setSuccessAlert(alertMessage);
        setTimeout(() => {
            setSuccessAlert('');
        }, 5000);
    };

    const handleFailureAlert = (alertMessage) => {
        setFailureAlert(alertMessage);
        setTimeout(() => {
            setFailureAlert('');
        }, 5000);
    };

    const handleCreateDraft = async () => {
        setMailDraft("");
        if (selectedPlatform === "") {
            handleFailureAlert("Please select a platform.");
            return;
        }
        const response = await fetch('https://anticounterfeit.co.in/api/products/mailReport/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                productDetails: productDetails,
                platform: selectedPlatform
            })
        });

        const json = await response.json();

        if (response.ok) {
            setMailDraft(json.result);
        } else {
            handleFailureAlert("Failed to generate");
        }
    }


    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                {/* Sender Email Selection */}
                <EmailAddress
                    selectedEmail={selectedEmail}
                    setSelectedEmail={setSelectedEmail}
                    receivingEmail={receivingEmail}
                    setReceivingEmail={setReceivingEmail}
                    setSelectedPlatform={setSelectedPlatform} // Pass function
                    selectedPlatform={selectedPlatform} // Pass state
                    platforms={platforms} // Pass platforms
                />

                {/* File Upload Section */}
                <Box>
                    <Text mb={2}>Upload Attachments:</Text>
                    <input
                        type="file"
                        multiple
                        onChange={handleFileUpload}
                        style={{ marginBottom: '1rem' }}
                    />
                    <VStack align="start">
                        {attachments.length > 0 &&
                            attachments.map((file, index) => (
                                <Text key={index} fontSize="sm">
                                    {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                </Text>
                            ))
                        }
                    </VStack>
                    <Box>
                        <Button
                            colorScheme="blue"
                            size="md"
                            onClick={handleCreateDraft}
                        >
                            Create Draft
                        </Button>
                    </Box>
                </Box>

                {/* Product Details */}
                {mailDraft.length > 0 && (
                    <Box mt={5}>
                        <Text fontWeight="bold" mb={2}>Email Template</Text>
                        <pre style={{ background: "#f4f4f4", padding: "10px", borderRadius: "5px" }}>
                            {mailDraft}
                        </pre>
                        <Button
                            mt={2}
                            colorScheme="blue"
                            size="sm"
                            onClick={() => navigator.clipboard.writeText(mailDraft)}
                        >
                            Copy to Clipboard
                        </Button>
                    </Box>
                )}
            </Card>

            {/* Alert Section */}
            <Stack spacing={3} position="fixed" right="10px" bottom="2vh">
                {successAlert !== '' &&
                    <Alert status="success" variant="left-accent" w="40vw">
                        <AlertIcon />
                        {successAlert}
                    </Alert>
                }
                {failureAlert !== '' &&
                    <Alert status="error" variant="left-accent" w="40vw">
                        <AlertIcon />
                        {failureAlert}
                    </Alert>
                }
            </Stack>
        </Box>
    );
};

export default Index;
