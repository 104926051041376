import React, { useState } from "react";
import {
    Box,
    useColorModeValue,
    Button,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import Card from "components/card/Card.js";

const Index = ({keywords, onMappingSubmit }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [columns, setColumns] = useState([]);
    const [mapping, setMapping] = useState({ url: "", keyword_name: "", prod_code: "" });
    const [fileData, setFileData] = useState([]); // Store the entire spreadsheet data

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                if (jsonData.length > 0) {
                    setColumns(jsonData[0]); // Set the first row as column headers
                    setFileData(jsonData); // Save the entire data for further use
                    onOpen(); // Open the modal to map columns
                }
                event.target.value = null;

            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleMappingChange = (e) => {
        const { name, value } = e.target;
        setMapping((prev) => ({ ...prev, [name]: value }));
    };

    const handleMappingSubmit = () => {
        const mappedData = fileData.slice(1).map((row) => {
            // Find the column index for each mapped field
            const urlIndex = columns.indexOf(mapping.url);
            const keywordIdIndex = columns.indexOf(mapping.keyword_name);
            const prodCodeIndex = columns.indexOf(mapping.prod_code);

            // Debug: Check the loaded keywords
            console.log(row[keywordIdIndex]);

            // Find the keyword ID by matching the name
            const keywordId = keywords.find((it) =>
                it.name.toLowerCase().trim() === (row[keywordIdIndex]?.toLowerCase().trim() || "")
            )?.id;

            // Map the data to the desired format
            return {
                url: urlIndex !== -1 ? row[urlIndex] : "",
                keyword_id: keywordId || "", // Assign an empty string if no match is found
                prod_code: prodCodeIndex !== -1 ? row[prodCodeIndex] : "",
            };
        });

        // console.log("Mapped Data:", mappedData);
        // console.log("Full File Data:", fileData);

        if (onMappingSubmit) {
            onMappingSubmit(mappedData ); // Send mapped and full file data to the parent
        }

        onClose();
    };

    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    return (
        <Box >
                <Flex justifyContent="flex-end">
                    <Button colorScheme="blue" as="label" htmlFor="file-upload">
                        Upload Spreadsheet
                    </Button>
                    <input
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        accept=".xls,.xlsx,.csv"
                        onChange={handleFileUpload}
                    />
                </Flex>
                <Flex justifyContent="flex-end">
                    <Text>*Map your spreadsheet columns to the required fields.</Text>
                </Flex>

            {/* Modal for Column Mapping */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Map Columns</ModalHeader>
                    <ModalBody>
                        <Text mb={2}>Please map the spreadsheet columns to the following fields:</Text>
                        <Box mb={4}>
                            <Text mb={1}>URL</Text>
                            <Select name="url" value={mapping.url} onChange={handleMappingChange} placeholder="Select column">
                                {columns.map((col, index) => (
                                    <option key={index} value={col}>
                                        {col}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <Box mb={4}>
                            <Text mb={1}>Brand name</Text>
                            <Select
                                name="keyword_name"
                                value={mapping.keyword_name}
                                onChange={handleMappingChange}
                                placeholder="Select column"
                            >
                                {columns.map((col, index) => (
                                    <option key={index} value={col}>
                                        {col}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <Box mb={4}>
                            <Text mb={1}>Product Code (Optional)</Text>
                            <Select
                                name="prod_code"
                                value={mapping.prod_code}
                                onChange={handleMappingChange}
                                placeholder="Select column"
                            >
                                {columns.map((col, index) => (
                                    <option key={index} value={col}>
                                        {col}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleMappingSubmit}>
                            Save Mapping
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Index;
