import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Input,
    Select,
    Text,
    VStack,
} from "@chakra-ui/react";

const DataInput = ({ keywords, onSave }) => {
    const [rows, setRows] = useState([
        { url: "", keyword_id: "", prod_code: "" },
    ]);

    const handleInputChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    const addRow = () => {
        setRows([...rows, { url: "", keyword_id: "", prod_code: "" }]);
    };

    const handleSave = () => {
        console.log("Saved Data:", rows);
        if (onSave) {
            onSave(rows); // Send data to the parent
        }
    };

    return (
        <Box p={4}>
            <VStack spacing={4} align="stretch">
                {rows.map((row, index) => (
                    <Flex key={index} alignItems="center" gap={4}>
                        {/* URL Input */}
                        <Input
                            placeholder="Enter URL"
                            value={row.url}
                            onChange={(e) =>
                                handleInputChange(index, "url", e.target.value)
                            }
                        />

                        {/* Keyword Dropdown */}
                        <Select
                            placeholder="Select Keyword"
                            value={row.keyword_id}
                            onChange={(e) =>
                                handleInputChange(index, "keyword_id", e.target.value)
                            }
                        >
                            {keywords.map((keyword) => (
                                <option key={keyword.id} value={keyword.id}>
                                    {keyword.name}
                                </option>
                            ))}
                        </Select>

                        {/* Product Code Input (Optional) */}
                        <Input
                            placeholder="Enter Product Code (Optional)"
                            value={row.prod_code}
                            onChange={(e) =>
                                handleInputChange(index, "prod_code", e.target.value)
                            }
                        />
                    </Flex>
                ))}
            </VStack>

            <Flex justifyContent="space-between" mt={4}>
                {/* Add More Button */}
                <Button colorScheme="teal" onClick={addRow}>
                    Add More
                </Button>

                {/* Save Button */}
                <Button colorScheme="blue" onClick={handleSave}>
                    Save Data
                </Button>
            </Flex>
        </Box>
    );
};

export default DataInput;
