import React, { useState } from 'react';
import {
    Box,
    useColorModeValue,
    Select,
    Stack,
    Alert,
    AlertIcon,
    Input,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel
} from "@chakra-ui/react";

const EmailAddress = (props) => {
    const [senderEmails, setSenderEmails] = useState(["email1@example.com", "email2@example.com", "email3@example.com"]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [selectedPlatform, setSelectedPlatform] = useState("");
    const [file, setFile] = useState(null);
    const [uploadType,setUploadType]=useState("")

    const handleEmailChange = (event) => {
        props.setSelectedEmail(event.target.value);
    };

    const handlePlatformChange = (event) => {
        props.setSelectedPlatform(event.target.value);
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    return (
        <Box mb={4}>
            <Stack mb={4}>
            <Button colorScheme="gray" size="xs" onClick={handleModalOpen} justifyContent="flex-end" >
                Upload EMAIL Report Template
                </Button>
            </Stack>
            <Stack direction="row" spacing={4} mb={4}>
    
                    <Select
                        placeholder="Select platform"
                        value={props.selectedPlatform}
                        onChange={handlePlatformChange}
                    >
                        {props.platforms.map((pf, index) => (
                            <option key={index} >
                                {pf.platform_name}
                            </option>
                        ))}
                    </Select>

                <Select
                    placeholder="Select sender email"
                    value={props.selectedEmail}
                    onChange={handleEmailChange}
                >
                    {senderEmails.map((email, index) => (
                        <option key={index} value={email}>
                            {email}
                        </option>
                    ))}
                </Select>
                <Input
                    placeholder="Enter email of receiver"
                    type="email"
                    onChange={(e) => props.setReceivingEmail(e.target.value)}
                />
                
            </Stack>

           

        </Box>
    );
};

export default EmailAddress;
