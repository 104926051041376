import React,{useState,useEffect} from 'react';
import {
  Box,
  useColorModeValue,
  SimpleGrid,
  Icon,
  Flex,
  Select,
  Divider,
  Stack,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import AddSpreadSheet from './components/addSpreadSheet';
import DataInput from './components/DataInput';
import { useListContext } from "../../../contexts/listContext";



const index = () => {
  const { list } = useListContext();
  const [successAlert, setSuccessAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');


  const handleMappingSubmit = async (fileData) => {
    console.log("Received Mapping Data:", fileData);

    try {
      // Make the API call to send the data
      const response = await fetch("https://anticounterfeit.co.in/api/products/addListingManually", {
        method: "POST", // HTTP method
        headers: {
          "Content-Type": "application/json", // Sending JSON data
        },
        body: JSON.stringify({ data: fileData }), // Send data in JSON format
      });

      if (response.ok) {
        // If the request was successful, show a success alert
        const responseData = await response.json(); // Get the response data if needed
        console.log("API Response:", responseData);
        handleSuccessAlert("Data saved successfully"); // Show success alert
      } else {
        // Handle error response
        const errorData = await response.json();
        console.error("Error:", errorData);
        handleFailureAlert("Failed to save data");
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Network error:", error);
      handleFailureAlert("An error occurred while saving data");
    }
  };





  const handleSuccessAlert = async (alertMessage) => {
    setSuccessAlert(alertMessage);
    setTimeout(() => {
      setSuccessAlert('');
    }, 5000);
  }

  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>

        <AddSpreadSheet keywords={list} onMappingSubmit={handleMappingSubmit} />
        <Divider colorScheme="blue" orientation='horizontal' />
        <DataInput keywords={list} onSave={handleMappingSubmit} />
      </Card>
      


      <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
        {successAlert !== '' &&
          <Alert status='success' variant='left-accent' w='40vw'>
            <AlertIcon />
            {successAlert}
          </Alert>
        }
        {failureAlert !== '' &&
          <Alert status='error' variant='left-accent' w='40vw'>
            <AlertIcon />
            {failureAlert}
          </Alert>
        }
      </Stack>
    </Box>
  )
}

export default index