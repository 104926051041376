import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    Text,
    Input,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";



const SheetDownload = ({ isOpen, onClose, products, platforms }) => {
    const [selectedPlatform, setSelectedPlatform] = useState("");
    const [columns, setColumns] = useState([]);
    const [newColumnName, setNewColumnName] = useState("");
    const [newColumnKey, setNewColumnKey] = useState("");
    const [abuseType, setAbuseType] = useState("");

    // Filter products where seller_status is "R"
    let filteredProducts = products.filter(
        (product) => product.seller_status === "R"
    );
    filteredProducts=filteredProducts.map((it)=>it.product)

    // Get product keys dynamically from the first product (assuming all products have similar keys)
    const productKeys = filteredProducts.length
        ? Object.keys(filteredProducts[0])
        : [];

    // Add a new column
    const handleAddColumn = () => {
        if (newColumnName && newColumnKey) {
            setColumns([...columns, { name: newColumnName, key: newColumnKey }]);
            setNewColumnName("");
            setNewColumnKey("");
        }
    };

    const handleReset = () => {
        setSelectedPlatform("");
        setColumns([]);
        setNewColumnName("");
        setNewColumnKey("");
        setAbuseType("");
    };

    // Export data to Excel
    const handleDownload = () => {
        const currentDate = new Date().toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        }).replace(",", ""); // Format: 12-Dec-2024

        const data = filteredProducts.map((product,index) => {
            let row = {
                "Sr. No":index+1
            };
            columns.forEach((col) => {
                row[col.name] = product[col.key] || ""; // Add dynamic columns
            });
            row["Abuse Type"] = abuseType;
            return row;
        });

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        let user_name = JSON.parse(localStorage.getItem("PAuser")).message.result.name;

        if (user_name) {
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            XLSX.writeFile(workbook, `${user_name}_${selectedPlatform}_${currentDate}.xlsx`);
        } else {
            alert("Please Login first")
        }
      
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Sheet Options</ModalHeader>
                <ModalBody>
                    {/* Platform Dropdown */}
                    <Box mb={4}>
                        <Text mb={2}>Select Platform:</Text>
                        <Select
                            placeholder="Select Platform"
                            value={selectedPlatform}
                            onChange={(e) => setSelectedPlatform(e.target.value)}
                        >
                            {platforms.map((platform) => (
                                <option key={platform.id} value={platform.platform_name}>
                                    {platform.platform_name}
                                </option>
                            ))}
                        </Select>
                    </Box>

                    {/* Dynamic Column Input */}
                    <Box mb={4}>
                        <Text mb={2}>Add Columns:</Text>
                        <Flex gap={2} mb={2}>
                            <Input
                                placeholder="Column Name"
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <Select
                                placeholder="Select Product Key"
                                value={newColumnKey}
                                onChange={(e) => setNewColumnKey(e.target.value)}
                            >
                                {productKeys.map((key) => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                            </Select>
                            <Button onClick={handleAddColumn} colorScheme="teal">
                                Add
                            </Button>
                        </Flex>

                        {columns.map((col, index) => (
                            <Text key={index}>
                                - {col.name}=>{col.key}
                            </Text>
                        ))}
                    </Box>

                    {/* Abuse Type Input */}
                    <Box mb={4}>
                        <Text mb={2}>Abuse Type:</Text>
                        <Input
                            placeholder="Enter Abuse Type"
                            value={abuseType}
                            onChange={(e) => setAbuseType(e.target.value)}
                        />
                    </Box>
                </ModalBody>

                {/* Footer Buttons */}
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleDownload}>
                        Download
                    </Button>
                    <Button onClick={() => {
                        handleReset();
                        onClose();
                    }}> Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SheetDownload;
